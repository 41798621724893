













import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import TourForm from '@/tour/TourForm.vue';
import EventForm from '@/event/EventForm.vue';
import SegmentedControl from '@/control/SegmentedControl.vue';
import EntityType from '@/common/entity.type';
import SharedFields from '@/add/shared.fields';

export default Vue.extend({
  components: { Scaffold, EventForm, TourForm, SegmentedControl },
  props: {
    type: {
      type: EntityType,
      default() {
        return EntityType.tour;
      },
    },
  },
  data() {
    return {
      shared: new SharedFields(),
      segment: EntityType.tour.id,
      segments: [
        {
          title: 'Tour',
          id: EntityType.tour.id,
        },
        {
          title: 'Event',
          id: EntityType.event.id,
        },
      ],
    };
  },
  computed: {
    isTourForm(): boolean {
      return EntityType.byId(this.segment).isTour;
    },
    isEventForm(): boolean {
      return EntityType.byId(this.segment).isEvent;
    },
  },
  mounted() {
    this.segment = this.type.id;
  },
});
